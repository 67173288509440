import { NavLink } from "react-router-dom";

export default function Logo() {
    return (
        <>
            <NavLink
                to={"/"}>
                <img src='./images/so_amazing_games_logo.jpg' className="App-logo" alt="logo" />
            </NavLink>
            <p>Game Development Studio</p>
            <p>
                <a href='https://www.linkedin.com/company/105455066' target="_blank" rel="noreferrer"><img src="./images/icon-linkedin-250.png" alt="So Amazing Games on LinkedIn" className="icon" /></a>
          <a href='https://instagram.com/soamazinggames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_06248_icon_062481_256.png" alt="So Amazing Games on Instagram" className="icon" /></a>
          <a href='https://twitter.com/SoAmazingGames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_01789_icon_017891_256.png" alt="So Amazing Games on Twitter / X" className="icon" /></a>
            </p>
        </>
    )
}

import Logo from './Logo'

export default function Home() {
  return (
    <main>


      {/* <img src='./images/meatysRevengeWeb.jpg' alt="Meaty's Revenge Banner" className='heroImage' /> */}



      <section className='outlinedContainer flexColumnCentered heroImage'>
        <Logo />
      </section>

      <section className='flexColumnCentered outlinedContainer halveItBackground'>
        <img className='gameThumbnail dropShadow' src='./images/halve-it-icon-1024.png' alt='Halve It! Icon' />
      </section>


      <section className='text outlinedContainer halveItBackground'>
        <div>
          <h1>Halve It!</h1>
          <p>If only everything was symmetrical… But sadly we are not living in a perfect world…</p>
          <p>In Halve It!, your only job is to cut things in half. Perfectly. No pressure.</p>

          <p>Just take your finger and slice across everyday objects — from donuts to dinosaurs, cupcakes to couches — and try to split them into two equal parts by volume. Sounds simple, right? Until you’re staring down a crooked pineapple or a sleeping cat-shaped pillow.</p>

          <p>Every cut is judged with cold mathematical precision: did you manage a perfect 50/50 split? Or are you shamefully living in the 48/52 zone?</p>
        </div>
      </section>



      <section className='outlinedContainer flexColumnCentered meatysBackground'>
        <img className='gameThumbnail dropShadow' src='./images/meatysRevengeWeb.jpg' alt="Meaty's Revenge mobile game" />
      </section>

      <section className='text outlinedContainer meatysBackground'>
        <div>
          <h1>Meaty's Revenge</h1>
          <p>In a not-so-distant future, an epic battle rages between meat and vegetables. Under the iron rule of King Avocado, a ruthless tyrant, vegetables have forced meat off plates across the globe.</p>
          <p>But all hope is not lost. A brave little Meatball rises to challenge the vegetable oppressors, embarking on a daring quest to reclaim kitchens and restore balance to the culinary world.
          </p>
        </div>
      </section>

      <section className=' outlinedContainer flexColumnCentered meatysBackground'>
        <div>
          <video loop={true} autoPlay playsInline muted={true} className="object-cover object-center w-full h-full absolute inset-0 phone-mask dropShadow"><source src="./videos/Meaty_0-0-3.m4v" type="video/mp4" />Sorry. Your browser does not support the video tag.</video>
          <div className='storeIcons'>
            <img src="./images/meatys_revenge_icon.jpg" alt="guess a word or die store icon" className="storeIcon dropShadow" />
            {/* <div>
              <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='_blank'><img src='./images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt="get on app store" className="getOnAppStore dropShadow" /></a>
              <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.guessawordordie' target='_blank'><img alt='Get it on Google Play' className="getOnAppStore dropShadow" src='./images/en_badge_web_generic.png' /></a>
            </div> */}
          </div>
        </div>
      </section>

      <section className='outlinedContainer flexColumnCentered meatysBackground'>
        <img className='gameThumbnail dropShadow' src='./images/Meatys_Revenge_OST_album_cover.jpg' alt="Meaty's Revenge mobile game" />
        <a href='https://music.apple.com/es/album/meatys-revenge-original-game-soundtrack/1804415423?l=en-GB'>
          <img src='./images/listen-on-apple-music-icon.png' alt="Listen on Apple Music" className="getOnAppStore dropShadow" />
        </a>
        <a href='https://open.spotify.com/album/5KJnsSf4Q8l3GxMDxCjPIZ?si=TojycllmRMSAfcChAbiNbw'>
          <img src='./images/listen-on-spotify.png' alt="Listen on Spotify" className="getOnAppStore dropShadow" />
        </a>
      </section>



      <section className='flexColumnCentered outlinedContainer pickyEaterBackground'>
        <img className='gameThumbnail dropShadow' src='./images/splash_picky_eater.jpg' alt='Picky Eater Go! Banner' />
      </section>


      <section className='text outlinedContainer pickyEaterBackground'>
        <div>
          <h1>Picky Eater Go!</h1>
          <p>You are a Blob. And you need to eat. But only food, not anything else!</p>
          <p>Unfortunately, life is not so easy and you have to be picky to catch only the food.</p>
          <p>On the bright side, there are plenty of food and some of it is colelctible! So you can collect all the best desserts, drinks or sweets 😋</p>
          <p>Sadly enough there are some evil entities trying to make your life harder, because they are evil duh!</p>
          <p>Eat your way through the levels and avoid disgusting non-food!</p>
        </div>
      </section>




      <section className='outlinedContainer flexColumnCentered pickyEaterBackground'>
        <div>


          <video loop={true} autoPlay playsInline muted={true} className="object-cover object-center w-full h-full absolute inset-0 phone-mask dropShadow"><source src="./videos/PickyEaterGo_promo_lv1-24.mp4" type="video/mp4" />Sorry. Your browser does not support the video tag.</video>
          <div className='storeIcons'>
            <img src="./images/picky_eater_go!_icon.png" alt="Picky Eater Go! store icon" className="storeIcon dropShadow" />
            <div>
              <a href='https://apps.apple.com/us/app/picky-eater-go/id6451353816' target='_blank'><img src='./images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt="get on app store" className="getOnAppStore dropShadow" /></a>
              <a href='https://play.google.com/store/apps/details?id=com.soamazing.games.pickyeatergo' target='_blank'><img alt='Get it on Google Play' className="getOnAppStore dropShadow" src='./images/en_badge_web_generic.png' /></a>
            </div>

          </div>
        </div>
      </section>














      <section className='outlinedContainer flexColumnCentered guessAwordBackground'>
        <img className='gameThumbnail dropShadow' src='./images/sausagedeath03.png' alt='Sausage Death Guess a Word or Die' />
      </section>


      <section className='text outlinedContainer guessAwordBackground'>
        <div>
          <h1>Guess a word or die</h1>
          <p>You know the words.</p>
          <p>Just guess them.</p>
          <p>Or someone is going to die...</p>
        </div>
      </section>

      <section className=' outlinedContainer flexColumnCentered guessAwordBackground'>
        <div>
          <video loop={true} autoPlay playsInline muted={true} className="object-cover object-center w-full h-full absolute inset-0 phone-mask dropShadow"><source src="./videos/RPReplay_Final1689412279.MP4" type="video/mp4" />Sorry. Your browser does not support the video tag.</video>
          <div className='storeIcons'>
            <img src="./images/guess_a_word_or_die_store_icon.png" alt="guess a word or die store icon" className="storeIcon dropShadow" />
            <div>
              <a href='https://apps.apple.com/us/app/guess-a-word-or-die/id1534154298' target='_blank'><img src='./images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg' alt="get on app store" className="getOnAppStore dropShadow" /></a>
              <a href='https://play.google.com/store/apps/details?id=com.barcelonacodeschool.guessawordordie' target='_blank'><img alt='Get it on Google Play' className="getOnAppStore dropShadow" src='./images/en_badge_web_generic.png' /></a>
            </div>
          </div>
        </div>
      </section>

      {/* <section className='outlinedContainer flexColumnCentered'>
      <div >
        <p>Game Development studio</p>
        <p>Barcelona</p>
        <p><a href='mailto:gk@soamazing.games'>Get in touch</a></p>
        <a href='https://www.linkedin.com/company/105455066' target="_blank" rel="noreferrer"><img src="./images/icon-linkedin-250.png" alt="So Amazing Games on LinkedIn" className="icon" /></a>
        <a href='https://instagram.com/soamazinggames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_06248_icon_062481_256.png" alt="So Amazing Games on Instagram" className="icon" /></a>
        <a href='https://twitter.com/SoAmazingGames' target="_blank" rel="noreferrer"><img src="./images/_i_icon_01789_icon_017891_256.png" alt="So Amazing Games on Twitter / X" className="icon" /></a>

      </div>
    </section> */}

    </main>
  )
}
